var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-stepper",
    {
      attrs: { "alt-labels": "", flat: "" },
      model: {
        value: _vm.stepper,
        callback: function ($$v) {
          _vm.stepper = $$v
        },
        expression: "stepper",
      },
    },
    [
      _c(
        "v-stepper-header",
        [
          _c(
            "v-stepper-step",
            {
              attrs: {
                complete: _vm.stepper > 1,
                color: "indigo darken-1",
                step: "1",
              },
            },
            [_vm._v("ジャンル決定 ")]
          ),
          _c("v-divider"),
          _c(
            "v-stepper-step",
            {
              attrs: {
                complete: _vm.stepper > 2,
                color: "indigo darken-1",
                step: "2",
              },
            },
            [_vm._v("形状決定 ")]
          ),
          _c("v-divider"),
          _c(
            "v-stepper-step",
            {
              attrs: {
                complete: _vm.stepper > 3,
                color: "indigo darken-1",
                step: "3",
              },
            },
            [_vm._v("規格決定 ")]
          ),
          _c("v-divider"),
          _c(
            "v-stepper-step",
            {
              attrs: {
                complete: _vm.stepper > 4,
                color: "red darken-1",
                step: "4",
              },
            },
            [_vm._v("商品確定 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }